import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const isLoggedIn = localStorage.getItem('token');
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const isAdmin = userData?.role === 'Admin';

  if (!isLoggedIn) return null;

  return (
    <div className="sidebar">
      <div className="sidebar-top">
        <Link to="/home" className="sidebar-item">
          <span>Home</span>
        </Link>
      </div>
      <div className="sidebar-bottom">
        <Link to="/integrations" className="sidebar-item">
          <span>Integrations</span>
        </Link>
        {isAdmin && (
          <Link to="/user-list" className="sidebar-item">
            <span>Settings</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
