import React, { useEffect, useRef, useState } from "react";
import {
  AddInsightChatQuestion,
  GetQuestionAnswerList,
} from "../../DAL/Chats/Chat";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import LoaderModal from "../../components/LoaderModel";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ReactMarkdown from "react-markdown";

export default function QuestionAnswer({
  chatuuid,
  userId,
  orgName,
  selectedEndpoint,
}) {
  const containerRef = useRef(null);
  const [data, setData] = useState([]);
  const [question, setQuestion] = useState("");
  const [openLoaderModel, setOpenLoaderModel] = useState(false);

  const setScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const GetData = async () => {
    setOpenLoaderModel(true);
    const resp = await GetQuestionAnswerList(chatuuid);
    if (resp?.status == 200) {
      setData(resp?.qnas);
      setOpenLoaderModel(false);
      setTimeout(() => {
        setScroll();
      }, 500);
    }
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    setOpenLoaderModel(true);
    const formData = new FormData();
    formData.append("question", question);
    formData.append("chat_uuid", chatuuid);
    formData.append("user_id", userId);
    formData.append("endpoint_type", selectedEndpoint);

    const resp = await AddInsightChatQuestion(formData);

    if (resp?.status == 200) {
      setQuestion("");
      setOpenLoaderModel(false);
      GetData();
    } else {
      setOpenLoaderModel(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleQuestionSubmit(e);
    }
  };

  useEffect(() => {
    GetData();
  }, [chatuuid]);
  return (
    <>
      <LoaderModal open={openLoaderModel} />
      <div className="m-auto Container90">
        <div
          ref={containerRef}
          style={{
            height: "73vh",
            overflowY: "scroll",
            scrollbarWidth: "none",
            marginBottom: "20px",
          }}
        >
          {data?.map((val) => (
            <>
              <Typography
                variant="body1"
                sx={{
                  border: "1px solid grey",
                  padding: "7px 20px",
                  borderRadius: "20px",
                  width: "fit-content",
                  marginLeft: "auto",
                }}
                className="fw-bold mt-3"
              >
                {val?.question}
              </Typography>

              <div className="mt-4 " style={{ width: "75%" }}>
                <ReactMarkdown className="markdown-content">
                  {val?.answer}
                </ReactMarkdown>
                <Tooltip title="Copy" className="p-0">
                  <IconButton onClick={() => handleCopy(val?.answer)}>
                    <FileCopyIcon sx={{ height: "20px", color: "black" }} />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="text-center">
        <TextField
          placeholder={`Message ${orgName}`}
          onChange={(e) => setQuestion(e.target.value)}
          value={question}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "50px", // Make sure to target the input element for border radius
            },
            width: "80%", // Increase the width of the TextField (adjust as needed)
          }}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="Group.png" alt="" style={{ height: "20px" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="button">
                  <img
                    src="arrow.png"
                    alt=""
                    style={{ height: "33px" }}
                    onClick={handleQuestionSubmit}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText
          sx={{ fontSize: "13px", marginTop: "20px", textAlign: "center" }}
        >
          By messaging <strong style={{ color: "black" }}>{orgName}</strong>,
          you agree to our
          <strong style={{ textDecoration: "underline", color: "black" }}>
            Terms
          </strong>
          and have read our
          <strong style={{ textDecoration: "underline", color: "black" }}>
            Privacy Policy.
          </strong>
        </FormHelperText>
      </div>
    </>
  );
}
