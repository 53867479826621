import { filter } from "lodash";

import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
// components
// import {
//   Page,
//   Label,
//   Scrollbar,
//   Iconify,
//   SearchNotFound,
// } from "src/components";
import UserListHead from "../../components/UserListHead";

// mock
// import USERLIST from "../../_mock/user";
//
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

import { useSnackbar } from "notistack";
import { AllUsers, DeleteUserData } from "../../DAL/Users/Users";
import Iconify from "../../components/Iconify";
import { MarginOutlined, MarginTwoTone } from "@mui/icons-material";
import LoaderModal from "../../components/LoaderModel";
import UsersModal from "./PendingUsersModel";
import FetchOldRecordingsModal from "./FetchOldRecordingModel";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: "full_name",
    label: "Full Name",
    alignCenter: true,
  },
  { id: "email", label: "Email", alignCenter: true },
  { id: "role", label: "Role", alignCenter: true },

  { id: "created_at", label: "Action", alignCenter: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function UserList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);

  const [userId, setUserId] = useState("");
  const [user, setUser] = useState();
  const [USERLIST, setUSERLIST] = useState([]);
  const [loading, setloading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [recOpenModel, setRecOpenModel] = useState(false);
  
  const getAllUsers = async (id) => {
    setloading(true);
    const resp = await AllUsers(id);
    if (resp?.status == 200) {
      setUSERLIST(resp);
    }
    setloading(false);
  };

  const handleDelete = async (id) => {
    setloading(true);
    const resp = await DeleteUserData(id);
    if (resp?.status == 200) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      getAllUsers(userId);
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
    setloading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const isUserNotFound = USERLIST.length === 0;

  useEffect(() => {
    const UserData = JSON.parse(localStorage.getItem("user_data"));
    setUser(UserData);
    setUserId(UserData?.user_id);
    getAllUsers(UserData?.user_id);
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <UsersModal open={openModel} setOpen={setOpenModel} />
      <FetchOldRecordingsModal open={recOpenModel} setOpen={setRecOpenModel} />
      <LoaderModal open={loading} />
      <>
        <Container maxWidth="lg" sx={{ marginTop: "5rem" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2.5}
            mt={5}
          >
            <Iconify
              icon={"ri:arrow-left-line"}
              width={25}
              height={25}
              sx={{
                cursor: "pointer",
                marginTop: "10px",
                marginLeft: "1rem",
              }}
              onClick={() => navigate("/home")}
            />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Users List
            </Typography>
            <div className="d-flex gap-2">
              <button className="active " onClick={() => setRecOpenModel(true)}>
                Seed Old Meetings
              </button>
              <button className="active " onClick={() => setOpenModel(true)}>
                Pending Users
              </button>
              {user?.role == "Admin" && (
                <button
                  className="active"
                  onClick={() => navigate("/add-user")}
                >
                  Add User
                </button>
              )}
            </div>
          </Stack>

          <Card
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              mb: 1,
              overflowX: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />

                <TableBody>
                  {USERLIST?.map((row) => {
                    const { id, email, full_name, role } = row;

                    const isItemSelected = selected.indexOf(full_name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="center" sx={{ fontSize: "13px" }}>
                          {full_name}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "13px" }}>
                          {email}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "13px" }}>
                          {role}
                        </TableCell>

                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleDelete(id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <h5>Data not found</h5>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </>
    </>
  );
}
