import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../components/Iconify";

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { LoginData } from "../../DAL/Auth/auth";
import LoaderModal from "../../components/LoaderModel";
import { ConstructionOutlined } from "@mui/icons-material";

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  localStorage.clear();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs?.password.length < 8) {
      enqueueSnackbar("Password contains minimum 8 characters", {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    const data = {
      email: inputs?.email,
      password: inputs?.password,
    };
    const resp = await LoginData(data);

    if (resp?.status == 200) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      localStorage.setItem("token", resp?.auth_token);
      console.log("---------> user_data", resp)
      localStorage.setItem("user_data", JSON.stringify(resp));
      navigate("/home");
    } else {
      setLoading(false);
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };
  return (
    <>
      <LoaderModal open={loading} />
      <div className="LoginMain">
        <form onSubmit={handleSubmit}>
          <Card
            sx={{
              width: 500,
              padding: "30px 12px 30px 12px",
              borderRadius: "15px",
            }}
          >
            <CardContent>
              <Typography variant="h5" className="fw-bold">
                Sign in
              </Typography>
              <div className="mt-3">
                <Typography variant="subtitle2" sx={{ marginTop: "20px" }}>
                  Email *
                </Typography>
                <TextField
                  size="small"
                  required
                  type="email"
                  name="email"
                  onChange={handleChange}
                  fullWidth
                />

                <Typography variant="subtitle2" sx={{ marginTop: "15px" }}>
                  Password *
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  required
                  onChange={handleChange}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="mt-4">
                <LoadingButton
                  fullWidth
                  size="medium"
                  className="active"
                  // style={{
                  //   borderRadius: "30px",
                  //   padding: "10px",
                  //   // backgroundColor: "rgb(26, 7, 197)",
                  //   color: "white",
                  // }}
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                >
                  {"Login"}
                </LoadingButton>
              </div>

              <div className="mt-2">
                <Typography variant="subtitle2">
                  Don't have an account?{" "}
                  <span
                    className="fw-bold"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => navigate("/register")}
                  >
                    Signup
                  </span>
                </Typography>
              </div>
            </CardContent>
          </Card>
        </form>
      </div>
    </>
  );
}
