import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ZoomIntegration, DeauthorizeZoom, GetIntegrations } from '../../DAL/Zoom/Zoom';

const Integrations = () => {
  const [loading, setLoading] = useState(false);
  const [uninstallLoading, setUninstallLoading] = useState(false);
  const [integrations, setIntegrations] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const userId = userData?.user_id;

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    try {
      const resp = await GetIntegrations(userId);
      setIntegrations(resp?.integrations);
    } catch (error) {
      enqueueSnackbar("Failed to fetch integration statuses", { variant: "error" });
    }
  };

  const integrateWithZoom = async () => {
    setLoading(true);
    const resp = await ZoomIntegration(userId);
    if (resp?.status === 200) {
      window.open(resp?.auth_url, "_blank");
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
    setLoading(false);
  };

  const uninstallZoom = async () => {
    setUninstallLoading(true);
    try {
      const resp = await DeauthorizeZoom(userId);
      console.log("resp<--------------", resp)
      if (resp?.status === 200) {
        // Update user data in localStorage to reflect Zoom being disabled
        const updatedUserData = { ...userData, zoom_enabled: false };
        localStorage.setItem('user_data', JSON.stringify(updatedUserData));
        enqueueSnackbar("Zoom integration has been removed", { variant: "success" });
        // Refresh integration statuses
        await fetchIntegrations();
      } else {
        enqueueSnackbar("Failed to remove Zoom integration", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while removing Zoom integration", { variant: "error" });
    }
    setUninstallLoading(false);
  };

  if (!integrations) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Integrations
      </Typography>

      <Box sx={{ 
        p: 3, 
        mb: 3, 
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 1
      }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {integrations.zoom.name}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {integrations.zoom.description}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            onClick={integrateWithZoom}
            disabled={loading || integrations.zoom.enabled}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {integrations.zoom.enabled ? 'Connected to Zoom' : 'Integrate with Zoom'}
          </Button>
          {integrations.zoom.enabled && (
            <Button
              variant="outlined"
              color="error"
              onClick={uninstallZoom}
              disabled={uninstallLoading}
              startIcon={uninstallLoading ? <CircularProgress size={20} /> : null}
            >
              Uninstall Zoom
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Integrations;
