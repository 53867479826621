import { invokeApi } from "../../utils";

export const AddInsightChatQuestion = async (formData) => {
  try {
    const endpointType = formData.get("endpoint_type");
    let endpoint = "/ask-question-from-summary"; // default

    switch (endpointType) {
      case "vectors":
        endpoint = "/ask-question-from-transcriptions-vectors";
        break;
      case "transcriptions":
        endpoint = "/ask-question-from-transcriptions";
        break;
      default:
        endpoint = "/ask-question-from-summary";
    }

    const response = await invokeApi({
      path: endpoint,
      method: "POST",
      postData: formData,
    });
    return response;
  } catch (error) {
    console.error("Error in AddInsightChatQuestion:", error);
    return error;
  }
};

export const OldChatQuestion = async (id) => {
  const requestObj = {
    path: `/old-insight-chats?user_id=${id}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const DeleteOldQuestion = async (id) => {
  const requestObj = {
    path: `/delete-insight-chat?chat_uuid=${id}`,
    method: "DELETE",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const GetQuestionAnswerList = async (id) => {
  const requestObj = {
    path: `/fetch-chat-qna?chat_uuid=${id}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
