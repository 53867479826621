import { invokeApi } from "../../utils";

export const ZoomIntegration = async (id) => {
  const requestObj = {
    path: `/sign-in-via-zoom?user_id=${id}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const FetchOldRecording = async (data) => {
  const requestObj = {
    path: `/fetch-zoom-calls`,
    method: "POST",
    postData: data,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const DeauthorizeZoom = async (userId) => {
  const requestObj = {
    path: `/deauth-zoom?user_id=${userId}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const GetIntegrations = async (userId) => {
  const requestObj = {
    path: `/integrations?user_id=${userId}`,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
