import {
  OutlinedInput,
  Typography,
  Box,
  CircularProgress,
  Button,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Iconify from "../../components/Iconify";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { AllRecordings } from "../../DAL/Recordings/Recording";
import { HandleStripePay, HandleStripeSuccess } from "../../DAL/Stripe/Stripe";
import BasicModal from "../ZoomModel/Model";
import LoaderModal from "../../components/LoaderModel";
import { useAppContext } from "../../Hooks/ContextApi";
import { useSnackbar } from "notistack";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import VideocamIcon from "@mui/icons-material/Videocam";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import StripeModal from "./Model";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  borderRadius: "10px",
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `#001f3f33 !important`,
  },
  "& legend": {
    display: "none",
  },
}));

export default function Home() {
  const { respData } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [val, setVal] = useState("personal");
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [stripePaid, setStripePaid] = useState(false);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [openLoaderModel, setOpenLoaderModel] = useState(false);
  const [openStripe, setOpenStripe] = useState(false);
  const [userId, setUserId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const getAllRecordings = async (id) => {
    setOpenLoaderModel(true);
    const resp = await AllRecordings(id, val, searchData);
    if (resp?.status == 200) {
      setData(resp);
      setOpenLoaderModel(false);
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setTimeout(() => {
      setSearchData(e.target.value);
    }, 3000);
  };

  const handleStripe = async () => {
    setOpenLoaderModel(true);
    const userData = JSON.parse(localStorage.getItem("user_data"));
    const resp = await HandleStripePay(userData.email, userData.user_id, "home");
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: resp.session_id,
    });
    if (error) {
      setOpenLoaderModel(false);
      console.error("Error redirecting to Stripe Checkout:", error);
    }
  };

  const handleStripeSuccess = async (userId) => {
    const resp = await HandleStripeSuccess(userId);
    if (resp.message == "Success") {
      enqueueSnackbar("Paid successfully...", { variant: "success" });
      const userData = JSON.parse(localStorage.getItem("user_data"));
      userData.paid = true;
      setStripePaid(true);
      localStorage.setItem("user_data", JSON.stringify(userData));
      setOpenLoaderModel(false);
      navigate("/");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const zoom = urlParams.get("zoom");
    const UserData = JSON.parse(localStorage.getItem("user_data"));
    setUserId(UserData?.user_id);
    if (UserData?.paid == false) {
      // setOpenStripe(true);
    }
    const stripeSuccess = urlParams.get("stripe_success");
    setStripePaid(UserData?.paid);
    const userId = urlParams.get("user_id");
    if (stripeSuccess == "true" && userId) {
      setOpenLoaderModel(true);
      handleStripeSuccess(userId);
    }
    if (stripeSuccess == "false") {
      enqueueSnackbar("Payment failed. Please try again...", {
        variant: "error",
      });
    }
    if (zoom == "true") {
      UserData.zoom_enabled = true;
      localStorage.setItem("user_data", JSON.stringify(UserData));
    }
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    if (respData) {
      setData(respData);
    }
    setRole(UserData?.role);
    if (UserData?.zoom_enabled == false) {
      setOpen(true);
    }
    if (respData == undefined) {
      getAllRecordings(UserData?.user_id);
    }
  }, [searchData, val]);
  return (
    <>
      {!stripePaid && (
        <Elements stripe={stripePromise}>
          <StripeModal
            open={openStripe}
            setOpen={setOpenStripe}
            role={role}
            paid={stripePaid}
            handleStripe={handleStripe}
          />
        </Elements>
      )}  
      <LoaderModal open={openLoaderModel} />
      <BasicModal open={open} setOpen={setOpen} />

      <div className="container m-auto">
        <div className="row mt-4">
          <div className="col-lg-4"></div>
          {/* <div className="col-lg-1"></div> */}
          <div className="col-lg-8 col-md-12 col-sm-12 d-flex align-items-center flex-wrap justify-content-end gap-2">
            <SearchStyle
              style={{
                height: "2.7rem",
                paddingBottom: "5px",
                marginTop: "5px",
              }}
              className="searchbar"
              placeholder="Search here"
              onChange={handleSearch}
              value={searchText}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "#001f3f", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
            <Button className="btnClass" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </div>

        <div className="mx-3 mt-4 d-flex flex-wrap gap-5 align-items-center">
          <div className="d-flex flex-wrap align-items-center gap-2">
            <Button
              className={val == "personal" ? "active" : "btnClass"}
              onClick={() => setVal("personal")}
              sx={{ cursor: "pointer" }}
            >
              List of Call
            </Button>

            <Button
              variant="outlined"
              className={val == "company" ? "active" : "btnClass"}
              onClick={() => setVal("company")}
              sx={{ cursor: "pointer" }}
            >
              Company
            </Button>
            <Button
              variant="outlined"
              className={val == "favourite" ? "active" : "btnClass"}
              onClick={() => setVal("favourite")}
              sx={{ cursor: "pointer" }}
            >
              Favorites
            </Button>
          </div>
          <div>
            <button
              className="InsightBtn"
              onClick={() => navigate("/user-insights", { state: userId })}
            >
              Universal Insights
            </button>
          </div>
        </div>
        <hr className="mx-2" />
        {data &&
          Object.keys(data).map((key) => {
            if (key != "status") {
              return (
                <div className="mt-5 mx-3" key={key}>
                  <span
                    style={{
                      borderRight: "1px solid silver",
                      paddingRight: "10px",
                    }}
                  >
                    <strong style={{ fontSize: "17px" }}>
                      <DateRangeIcon /> {key.split("_").join(" ")}
                    </strong>
                  </span>
                  <span style={{ marginLeft: "10px", color: "silver" }}>
                    {data[key]?.length} Call
                  </span>
                  {data[key].map((rec) => {
                    return (
                      <div className="mt-3 mb-4" key={rec.uuid}>
                        <div
                          style={{
                            borderRadius: "20px",
                            boxShadow:
                              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
                            padding: "15px 25px 15px 25px",
                            marginTop: "10px",
                          }}
                        >
                          <div className="row ">
                            <div className="col-lg-7 ">
                              <Typography
                                variant="h6"
                                className="fw-bold mt-2 d-flex gap-2"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/call-detail/${rec?.uuid}`)
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 35,
                                    height: 35,
                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <VideocamIcon
                                    sx={{ fontSize: 24, color: "#1a2a4b" }}
                                  />
                                </span>
                                {rec?.topic}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                className="mt-3 d-flex gap-2 align-items-center"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 35,
                                    height: 35,
                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <PersonIcon
                                    sx={{ fontSize: 24, color: "#1a2a4b" }}
                                  />
                                </span>
                                {rec?.participants}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                className="mt-3 d-flex gap-2 align-items-center"
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 35,
                                    height: 35,
                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <DateRangeIcon
                                    sx={{ fontSize: 24, color: "#1a2a4b" }}
                                  />
                                </span>
                                {rec?.start_time.split("_").join(" ")}
                              </Typography>
                            </div>
                            <hr className="mt-3" />
                            <div className="col-lg-12 mt-3">
                              <h5>Call Summary</h5>
                              {rec?.summary}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
      </div>
    </>
  );
}
